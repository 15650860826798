import './App.css';

function App() {
  return (
    <div>
          <h1>Learn React</h1>
    </div>
  );
}

export default App;
